import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import usersService from "./usersService";

const initialState = {
    users: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ' '
};

export const getUsersByRole = createAsyncThunk('users/getUsersByRole',

    async (role, thunkAPI) => {
        try {
            return await usersService.getUsersByRole(role)
        } catch (error) {
            const errorMessage = error.response.data;
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
)

export const createUserInAdminPanel = createAsyncThunk('users/createUserInAdminPanel',

    async (userData, thunkAPI) => {
        try {
            return await usersService.createUserInAdminPanel(userData)
        } catch (error) {
            const errorMessage = error.response.data;
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
)

export const updateUser = createAsyncThunk('users/updateUser',

    async (user, thunkAPI) => {
        try {
            return await usersService.updateUser(user)
        } catch (error) {
            const errorMessage = error.response.data;
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
)

export const removeUser = createAsyncThunk('users/remove',

    async (_id, thunkAPI) => {
        try {
            return await usersService.removeUser(_id)
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)



export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUsersByRole.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUsersByRole.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.users = action.payload
            })
            .addCase(getUsersByRole.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })


            .addCase(createUserInAdminPanel.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createUserInAdminPanel.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.users.unshift(action.payload)
             })
            .addCase(createUserInAdminPanel.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(updateUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = 'Error update user'
                state.users = null
            })

            .addCase(removeUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(removeUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.users = state.users.filter(
                    (user) => user._id != action.payload
                  )
            })
            .addCase(removeUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }

})

export const {} = usersSlice.actions;

export const usersReducer = usersSlice.reducer;