import axios from "axios";
import { API_URL } from "../../const";


const getUsersByRole = async (role) => {
    
    const token = localStorage.getItem('token');
    const res = await axios.get(`${API_URL}/api/user/get-users?withoutOwner=true`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
    return res.data;
}

const createUserInAdminPanel = async (userData) => {
    
    const token = localStorage.getItem('token');
    const res = await axios.post(`${API_URL}/api/user/create-user-in-admin-panel`, userData,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
    return res.data;
}

const updateUser = async (user) => {
    
    const token = localStorage.getItem('token');
    const res = await axios.put(`${API_URL}/api/user/update-user`, user,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );

    return res.data;
}

const removeUser = async (_id) => {

    const token = localStorage.getItem('token');
    await axios.delete(`${API_URL}/api/user/` + _id,
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
);
    return _id;
}

const usersService = {
    getUsersByRole,
    createUserInAdminPanel,
    updateUser,
    removeUser
}

export default usersService;