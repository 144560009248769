import React from 'react';
import './Cookies.css';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { acceptAllCookies } from '../../store/slices/cookies';



const Cookies = () => {

    const dispatch = useDispatch()

    const acceptCookies = () => {
        localStorage.setItem('acceptAllCookies', 'true');
        document.querySelector('.cookies-wrapper').style.display = 'none';
        dispatch(acceptAllCookies(true))
    }

    return (
        <div className='cookies-wrapper'>
            <p>
                We use cookies to enhance your user experience. <br />
                By using this website you agree to our <NavLink to='/privacy'>Cookie Policy.</NavLink>
            </p>
            <button className='cookies-button' onClick={() => acceptCookies()}>Accept</button>
            <button className='cookies-button' onClick={() => acceptCookies()}>Reject</button>
        </div>
    )
}

export default Cookies;