import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";



const CheckRoles = ({ children, roles }) => {

    const { user } = useSelector(state => state.auth);

    let rolesThatCoincided = [];

    user && roles && roles.forEach((role) => {
        if (user?.roles?.includes(role)) {
            rolesThatCoincided.push(role);
        }
    })

    if (rolesThatCoincided.length === 0) {
        return <Navigate to='/admin-panel/' />
    }

    return !user ? null : children;
};

export default CheckRoles;