import axios from "axios";
import { API_URL } from "../../const";



const login = async (userData) => {

    const res = await axios.post(`${API_URL}/api/auth/login`, userData)
    if (res.data) {
        localStorage.setItem('token', res.data)
    }

    return res.data
}

const getUser = async (token) => {

    const res = await axios.get(`${API_URL}/api/auth/me`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

    return res.data
}

const logout = async () => {
    localStorage.removeItem('token')
}

const passwordRecovery = async (email) => {

    const res = await axios.post(`${API_URL}/api/auth/password-recovery`, email)
    return res.data
}

const newPassword = async (data) => {

    const res = await axios.post(`${API_URL}/api/auth/new-password`, data)
    return res.data
}

const authService = {
    login,
    getUser,
    logout,
    passwordRecovery,
    newPassword,
}

export default authService;