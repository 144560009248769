import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";



const initialState = {
    user: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

export const login = createAsyncThunk('auth/login',

    async (user, thunkAPI) => {
        try {
            return await authService.login(user)
        } catch (error) {
            const errorMessage = error.response.data
            return thunkAPI.rejectWithValue(errorMessage)
        }
    }
)

export const getUser = createAsyncThunk('auth/getUser',

    async (token, thunkAPI) => {
        try {
            return await authService.getUser(token)
        } catch (error) {
            const errorMessage = error.response.data
            return thunkAPI.rejectWithValue(errorMessage)
        }
    }
)

export const logout = createAsyncThunk('auth/logout',
    async () => {
        await authService.logout()
    }
)

export const passwordRecovery = createAsyncThunk('auth/passwordRecovery',

    async (email, thunkAPI) => {
        try {
            return await authService.passwordRecovery(email)
        } catch (error) {
            const errorMessage = error.response.data
            return thunkAPI.rejectWithValue(errorMessage)
        }
    }
)

export const newPassword = createAsyncThunk('auth/newPassword',

    async (data, thunkAPI) => {
        try {
            return await authService.newPassword(data)
        } catch (error) {
            const errorMessage = error.response.data
            return thunkAPI.rejectWithValue(errorMessage)
        }
    }
)




export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.user = null
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ' '
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(getUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(getUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })
            .addCase(logout.fulfilled, (state, action) => {
                state.user = null
            })
            .addCase(passwordRecovery.pending, (state) => {
                state.isLoading = true
            })
            .addCase(passwordRecovery.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(passwordRecovery.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(newPassword.pending, (state) => {
                state.isLoading = true
            })
            .addCase(newPassword.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(newPassword.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }

})

export const { reset } = authSlice.actions;

export const authReducer = authSlice.reducer;