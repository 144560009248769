import React, { useState, useEffect, useRef } from 'react';
import './Industries.css';
import { TEXTS } from '../../texts';
import IndustriesCard from './IndustriesCard/IndustriesCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Keyboard } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
import DesignCover from '../../img/DesignCover.webp';
import IOSCover from '../../img/IOSCover.webp';
import FlutterCover from '../../img/FlutterCover.webp';
import AndroidCover from '../../img/AndroidCover.webp';
import WebCover from '../../img/WebCover.webp';
import QACover from '../../img/QACover.webp';


const Industries = () => {

  const swiperRef = useRef(null);

  useEffect(() => {
    document.querySelectorAll('.industries-card_wrapper').forEach(function (wrapper) {
      wrapper.style.marginBottom = "0px";
    })
  }, []);

  const [statusCard1, setStatusCard1] = useState('open');
  const [statusCard2, setStatusCard2] = useState(null);
  const [statusCard3, setStatusCard3] = useState(null);
  const [statusCard4, setStatusCard4] = useState(null);
  const [statusCard5, setStatusCard5] = useState(null);
  const [statusCard6, setStatusCard6] = useState(null);

  const [counter, setCounter] = useState('01');

  const toSlide = (index) => {
    if (swiperRef && swiperRef.current) {
      swiperRef.current.swiper.slideTo(index, 500, false);
    }
  }

  const industriesCards = [
    {
      title: TEXTS.DESIGN,
      number: TEXTS.number01,
      text: [TEXTS.designDescription],
      cover: DesignCover,
      statusCard: statusCard1
    },
    {
      title: TEXTS.iOS,
      number: TEXTS.number02,
      text: [TEXTS.iosDescription],
      cover: IOSCover,
      statusCard: statusCard2
    },
    {
      title: TEXTS.FLUTTER,
      number: TEXTS.number03,
      text: [TEXTS.flutterDescription],
      cover: FlutterCover,
      statusCard: statusCard3
    },
    {
      title: TEXTS.ANDROID,
      number: TEXTS.number04,
      text: [TEXTS.androidDescription],
      cover: AndroidCover,
      statusCard: statusCard4
    },
    {
      title: TEXTS.WEB,
      number: TEXTS.number05,
      text: [TEXTS.webDescription],
      cover: WebCover,
      statusCard: statusCard5
    },
    {
      title: TEXTS.QA,
      number: TEXTS.number06,
      text: [TEXTS.qaDescription],
      cover: QACover,
      statusCard: statusCard6
    }
  ]


  return (
    <div className="industries" >

      <div className="industries2" >

        <div className="industries-counter-wrapper">
          <p className='industries-counter'>{counter}</p>/<p className='industries-counter-total'>06</p>
        </div>


        <div className="industries-wrapper"  >

          <div className="industries-card industries-card-info" style={{ borderTop: 'none', backgroundColor: '#FDFDFD' }}>
            <div className="industries-content">
              <h3 className="industries-title">{TEXTS.softwareDevelopmentIndustriesTitle}</h3>
              <p className="industries-text">{TEXTS.nitrixTeamExperience}</p>
            </div>
          </div>

          <Swiper
            ref={swiperRef}
            modules={[Mousewheel, Keyboard]}


            onSlideChange={(slide) => {

              if (slide.realIndex === 0) {
                setCounter('01')

                setStatusCard1('open')
                setTimeout(() => {
                  slide.params.mousewheel.releaseOnEdges = true;
                }, 800)

                let content = document.querySelector('.industries-content')
                content.classList.remove('industries-content-animation')

                if (statusCard2 === 'open') setStatusCard2('closed')
                if (statusCard3 === 'open') setStatusCard3('closed')
              }



              if (slide.realIndex === 1) {
                setCounter('02')

                setStatusCard2('open')
                slide.params.mousewheel.releaseOnEdges = false;

                let content = document.querySelector('.industries-content')
                content.classList.add('industries-content-animation')

                if (statusCard1 === 'open') setStatusCard1('closed')
                if (statusCard3 === 'open') setStatusCard3('closed')
                if (statusCard4 === 'open') setStatusCard4('closed')
              }

              if (slide.realIndex === 2) {
                setCounter('03')

                setStatusCard3('open')
                slide.params.mousewheel.releaseOnEdges = false;

                if (statusCard2 === 'open') setStatusCard2('closed')
                if (statusCard4 === 'open') setStatusCard4('closed')
                if (statusCard1 === 'open') setStatusCard1('closed')
                if (statusCard5 === 'open') setStatusCard5('closed')
              }

              if (slide.realIndex === 3) {
                setCounter('04')

                setStatusCard4('open')
                slide.params.mousewheel.releaseOnEdges = false;

                if (statusCard3 === 'open') setStatusCard3('closed')
                if (statusCard5 === 'open') setStatusCard5('closed')
                if (statusCard2 === 'open') setStatusCard2('closed')
                if (statusCard6 === 'open') setStatusCard6('closed')
              }

              if (slide.realIndex === 4) {
                setCounter('05')

                setStatusCard5('open')
                slide.params.mousewheel.releaseOnEdges = false;

                if (statusCard4 === 'open') setStatusCard4('closed')
                if (statusCard6 === 'open') setStatusCard6('closed')
                if (statusCard3 === 'open') setStatusCard3('closed')
              }



              if (slide.realIndex === 5) {
                setCounter('06')

                setStatusCard6('open')
                setTimeout(() => {
                  slide.params.mousewheel.releaseOnEdges = true;
                }, 800)

                if (statusCard5 === 'open') setStatusCard5('closed')
                if (statusCard4 === 'open') setStatusCard4('closed')
              }

            }}


            normalizeSlideIndex={true}
            mousewheel={{
              sensitivity: 0.7,
              forceToAxis: true
            }}

            simulateTouch={true}
            touchRatio={0.3}
            grabCursor={true}
            keyboard={{
              enabled: true,
              onlyInViewport: true,
              pageUpDown: true
            }}
            breakpoints={{
              768: {
                slidesPerView: 4,
                spaceBetween: 0
              },
              1366: {
                slidesPerView: 5,
                spaceBetween: 0
              },
              1920: {
                slidesPerView: 6,
                spaceBetween: 0
              }
            }}
            className='slider-services-main'
          >


            <SwiperSlide style={{ height: 'auto' }}></SwiperSlide>

            {industriesCards.map((card, index) => (
              <SwiperSlide key={index} id={card.title} onClick={() => toSlide(index)}>
                <IndustriesCard
                  title={card.title}
                  number={card.number}
                  text={card.text}
                  cover={card.cover}
                  statusCard={card.statusCard}
                />
              </SwiperSlide>
            ))}

            <SwiperSlide></SwiperSlide>
            <SwiperSlide></SwiperSlide>
            <SwiperSlide className='industries-card-768none'></SwiperSlide>
            <SwiperSlide className='industries-card-1366none'></SwiperSlide>

          </Swiper>


        </div>



        <div className="industries-wrapper-mobile" >

          <div className="industries-content">
            <h3 className="industries-title">{TEXTS.softwareDevelopmentIndustriesTitle}</h3>
            <p className="industries-text">{TEXTS.nitrixTeamExperience}</p>
          </div>

          {industriesCards.map((card, index) => (
            <IndustriesCard
              title={card.title}
              number={card.number}
              text={card.text}
              cover={card.cover}
              key={index}
            />
          ))}

        </div>

      </div>
    </div >
  )
}

export default Industries;