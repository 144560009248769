import React, { useEffect, useState, useRef } from 'react';
import './About.css';
import { TEXTS } from '../../texts';
import InformationList from '../CompanyPresentation/InformationList/InformationList';
import cursor from '../../img/about_cursor.webp';



const About = () => {

    const [card1, setCard1] = useState(true);
    const [card2, setCard2] = useState(false);
    const [card3, setCard3] = useState(false);
    const [card4, setCard4] = useState(false);
    const [flag, setFlag] = useState(false);

    const [resX, setResX] = useState();
    const [resY, setResY] = useState();

    useEffect(() => {

        setTimeout(() => {
            setCard1(false);
            setCard2(true);
        }, 2000);
        setTimeout(() => {
            setCard2(false);
            setCard3(true);
        }, 4000);
        setTimeout(() => {
            setCard3(false);
            setCard4(true);
        }, 6000);
        setTimeout(() => {
            setCard4(false);
            setCard1(true);
            setFlag(!flag);
        }, 8000);

    }, [flag]);


    const textforObserver = useRef();
    const [textInView, setTextInView] = useState();

    useEffect(() => {
        const observerForText = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setTextInView(entry.isIntersecting);

              if (entry.isIntersecting) {
            observerForText.unobserve(textforObserver.current);
          }
        });
        observerForText.observe(textforObserver.current);

    }, []);



    function loopAnimation(e) {

        const cursor = document.querySelector('.about-cursor');
        const div = document.querySelector('.about');

        let x = e.pageX;
        let y = e.pageY;

        let percW = div.offsetWidth / 100;
        let percH = div.offsetHeight / 100;
        let resW = x - div.offsetLeft;
        let resH = y - div.offsetTop;

        setResX(resW / percW);
        setResY(resH / percH);

        cursor.style.top = `${resY}%`;
        cursor.style.left = `${resX}%`;
        cursor.style.width = `150px`;
        cursor.style.height = `150px`;
        cursor.classList.add('active')
    }


    function animationStart() {

        const cursor = document.querySelector('.about-cursor');
        cursor.style.transition = 'ease .0s';
        cursor.classList.add('cursor-start');

        setTimeout(() => {
            cursor.classList.remove('cursor-start');
        }, 500);
    }

    function animationEnd() {
        const cursor = document.querySelector('.about-cursor');
        cursor.style.transition = 'ease .2s';
        cursor.style.width = `0px`;
        cursor.style.height = `0px`;
    }

    return (
        <div id="arrow-down" className="about"
            onMouseMove={e => loopAnimation(e)}
            onMouseEnter={animationStart}
            onMouseLeave={animationEnd}
        >

            <a href="/company" style={{ cursor: 'none' }}>

                <img src={cursor} alt="" className='about-cursor' />

                <div className="about-wrapper"  >

                    <div className="about-content"  >

                        <h3 className="about-title">{TEXTS.aboutWhoWeAreTitle}</h3>
                        <p className="about-text" ref={textforObserver}>{TEXTS.aboutWhoWeAreText} </p>
                        <p className="about-link" >
                            <span ref={textforObserver}> {TEXTS.aboutGetToKnowUs} </span>
                            <svg className="about-link-svg" width="40" height="16" viewBox="0 0 40 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M33.5055 0.798304L39.7143 7.27983C40.0952 7.67757 40.0952 8.32243 39.7143 8.72017L33.5055 15.2017C33.1245 15.5994 32.5068 15.5994 32.1258 15.2017C31.7448 14.804 31.7448 14.1591 32.1258 13.7614L36.6691 9.01847H0L0 6.98153H36.6691L32.1258 2.23864C31.7448 1.8409 31.7448 1.19604 32.1258 0.798304C32.5068 0.400565 33.1245 0.400565 33.5055 0.798304Z" fill="#28C0E2" />
                            </svg>
                        </p>
                    </div>

                    <div className="about-inner" >
                        {card1 && (
                            <div className="about-card-x" >
                                <h5 className="card-title">{TEXTS.aboutRevenue} </h5>
                                <p className="card-text">$50M+</p>
                            </div>
                        )}
                        {card2 && (
                            <div className="about-card-x" >
                                <h5 className="card-title"> {TEXTS.aboutCountries}</h5>
                                <p className="card-text">100+</p>
                            </div>
                        )}
                        {card3 && (
                            <div className="about-card-x" >
                                <h5 className="card-title">{TEXTS.aboutWorkHours}</h5>
                                <p className="card-text">50k+</p>
                            </div>
                        )}
                        {card4 && (
                            <div className="about-card-x" >
                                <h5 className="card-title">{TEXTS.aboutCustomers} </h5>
                                <p className="card-text">100%</p>
                            </div>
                        )}
                    </div>

                    <div className="about-inner-mobile">
                        <InformationList inView={textInView} />
                    </div>
                </div>
            </a>

        </div>
    )
}

export default About;