


export const TEXTS = {

    // ---Header---

    COMPANY: "COMPANY",
    CASES: "CASES",
    SERVICES: "SERVICES",
    DEDICATED_TEAM: "DEDICATED TEAM",
    OUTSTAFF: "OUTSTAFF",
    OUTSOURCE: "OUTSOURCE",
    CAREERS: "CAREERS",
    BLOG: "BLOG",
    CONTACT_US: "CONTACT US",

    ddtShortDiscriptionInHeader: "You can scale the team you want to work with. It allows you to pick specialists that should cover all your needs.",
    outstaffShortDiscriptionInHeader: "Share your ideas with us and we will help you choose your dream team. You can control the workers at every stage of development.",
    outsourceShortDiscriptionInHeader: "Hiring the Nitrix team is a lucky ticket for companies that want to convert their idea to reality in the best way. We offer remote staff for most computer-based jobs.",

    scroll: "Scroll",

    LINKEDIN: "LinkedIn",
    EMAIL: "E-mail",
    UPWORK: "Upwork",
    INSTAGRAM: "Instagram",
    SKYPE: "Skype",



    // ---About---

    aboutWhoWeAreTitle: "Who we are",
    aboutWhoWeAreText: "Nitrix is ready to provide you with a full cycle of web and mobile development. We turn your dreams into real projects that are loved by users all over the world. Don't delay, cooperate with us, and start benefiting from the first minute.",

    aboutGetToKnowUs: "Get to know Us",

    aboutRevenue: "Clients’ overall revenue",
    aboutCountries: "Countries",
    aboutWorkHours: "Work hours",
    aboutCustomers: "Satisfied customers",
    aboutYearsInBusiness: "Years in business",




    // ---Industries---

    softwareDevelopmentIndustriesTitle: "Our Software Development Industries",
    nitrixTeamExperience: "The Nitrix team has extensive experience in IT. It allows us to provide you with high-quality services and meet all deadlines.",

    DESIGN: "DESIGN",
    iOS: "iOS",
    FLUTTER: "FLUTTER",
    ANDROID: "ANDROID",
    WEB: "WEB",
    QA: "QA",

    number01: "/01",
    number02: "/02",
    number03: "/03",
    number04: "/04",
    number05: "/05",
    number06: "/06",

    designDescription: "We are professionals in the creation of corporate identity, prototypes, wireframe modelling, and Ul/UX design. Each of these steps makes your product unique. Nitrix is ready to develop your project based on your wishes and preferences. As a result, you get a wonderful and functional design that attracts new users.",
    iosDescription: "We use Objective-C and Swift for your iOS projects. It allows us to create customized products for a wide audience. Each added technology increases your chance of success and provides your project with positive feedback.",
    flutterDescription: "Flutter is ideal for a hybrid or cross-platform application. It is a modern application development framework created by Google that allows us to develop native-like experience apps for iOS and Android.",
    androidDescription: "Nitrix developers have impressive experience working with Java and Kotlin. Therefore, there are no limits to your wishes. Our applications are ideal not only for smartphones but also for watches, smart TVs, and cars.",
    webDescription: "We develop both back-end and front-end development. This way we can provide you with the necessary functionality. You will enjoy a powerful final result thanks to our competence.",
    qaDescription: "Our qualified engineers meticulously check every element of your project. They do their best to create a product that works smoothly and efficiently. That is why your program or application will be convenient to use.",



    // --- Cases ---

    casesTitle: "Experience",
    casesSubtitle: "We collaborate with our clients to solve real problems",

    ourCases: "Our Cases",
    NitrixSupportsStartupsAndCompanies: "Nitrix supports startups and covers the needs of experienced companies. Each case is filled with a stylish design and functionality necessary for work.",



    StartupShow: "Startup Show",
    Joyn: "Joyn",
    AquaPal: "Aqua Pal",
    SaQure: "SaQure",
    Zise: "Zise",
    Ziksen: "Ziksen",
    Rainbow: "Rainbow",
    Halves: "Halves",
    Tailors: "Tailors",
    IsraelTV: "Israel TV",
    Joinuz:"Joinuz",

    StartupShowYear: "USA",
    JoynYear: "USA",
    AquaPalYear: "Ukraine",
    SaQureYear: "Netherlands",
    ZiseYear: "Europe",
    ZiksenYear: "Senegal",
    RainbowYear: "Canada",
    HalvesYear: "USA",
    TailorsYear: "Ukraine",
    IsraelTVYear: "Israel",
    JoinuzYear:"Netherlands",

    StartupShowAppDescription: "MOVIE APP",
    JoynAppDescription: "MASTER OPERATOR APP",
    AquaPalAppDescription: "WATER TRACKER APP",
    SaQureAppDescription: "MASSAGE APP",
    ZiseAppDescription: "WALLET APP",
    ZiksenAppDescription: "MUSIC APP",
    RainbowAppDescription: "SOCIAL APP",
    HalvesAppDescription: "DATING APP",
    TailorsAppDescription: "E-COMMERCE APP",
    IsraelTVAppDescription: "MOVIE APP",
    JoinuzAppDescription: "PROGRESSIVE WEB APP",

    StartupShowShortDescription: "With Startup Show, you can add all of your favorite m3u playlists using our sleek-designed powerful built-in player.",
    JoynShortDescription: "Joyn is the Master Operator app of EV charging stations, aggregating all private charging stations under a single, socially driven network, enabling EV users to charge and pay easily.",
    AquaPalShortDescription: "Aqua Pal is a Water Tracker, that allows you to track how much water you drink with a click of a button. This is just what you need to make sure you stay healthy, hydrated and energetic! Tracking water in its basic stages: Drink water at a set time, record it in the app and follow the evolution of your plant.",

    StartupShowDescription: ['With Startup Show, you can add all of your favorite m3u playlists using our sleek-designed powerful built-in player.', 'Being a Netflix competitor, this app empowers to watch movies, TV Shows and Live TV Streaming using VLC Player (iOS/Android) and ExoPlayer with FFmpeg (Android) depending on video format.'],
    JoynDescription: "Joyn is the Master Operator app of EV charging stations, aggregating all private charging stations under a single, socially driven network, enabling EV users to charge and pay easily.",
    AquaPalDescription: "Aqua Pal is a Water Tracker, that allows you to track how much water you drink with a click of a button. This is just what you need to make sure you stay healthy, hydrated and energetic! Tracking water in its basic stages: Drink water at a set time, record it in the app and follow the evolution of your plant.",
    SaQureDescription: "SaQure is an on-demand massage service platform, where one type of users are specialists that can provide their services, and others are regular users, customers, who can schedule an appointment.",
    ZiseDescription: "Zise is a next-generation digital wallet designed specifically for storing and managing Nexa coins, a fast and secure cryptocurrency built on the Nexa blockchain.",
    ZiksenDescription: "This is an Android app for audio music listening and clips watching. The application initially made for users from developing countries and should have spread through mobile phone operators.",
    RainbowDescription: "Social photo service application made as an Instagram prototype. The app has its such own features like sharing photos and photo collections with elements of a social network. The app is currently not available in the Stores.",
    HalvesDescription: "Halves is a dating app that helps single people to find their soulmates. The application made only for iOS developed from design to the Store and contains basic features: liking interesting profiles, matching, free chatting.",
    TailorsDescription: "Tailors app gives the opportunity to select a fabric, add to cart, pay for an order, pick a type of transportation in two ways-delivery to customer's address or takeaway.",
    IsraelTVDescription: "Israel TV gives you access to the best movies, TV Shows, Live TV Streaming and radio stations from Israel. With this app you will enjoy  watching Israel TV channels or listening to online Israel radio broadcasts and music on your devices, no matter where you are.",
    JoinuzDescription: "Joinuz is a corporate progressive web app that offers various features, such as receiving notifications related to work, tracking working hours, accessing a blog, and obtaining information about training. ",



    // --- Services on Home---

    servicesTitleOnHome: "Set up your own Development Team",
    DDT: "DDT",
    learnMore: "Learn more",
    outsourceDiscriptionOnHome: "IT outsourcing is about the rational use of resources. Such a service will save you from time-consuming tasks that require special attention. Thanks to the professionalism of our employees, we can cope with tasks of any complexity. Delegate your work to Nitrix and convince yourself of our expertise.",
    outstaffDiscriptionOnHome: "Provide yourself with additional employees with the necessary skills. Nitrix is a company that offers outstaffing services. We understand your problems and are ready to provide the best specialists for your project. Our professionals will work hard on your tasks and deliver impressive results.",
    ddtDiscriptionOnHome: "Hire a whole team of professionals who can develop your project from the beginning to the end. It allows you to take IT to a new level and impress your target audience. The Nitrix team is made of people who work in different directions for the greatest result.",




    // --- Footer ---

    footerSocialTitle: "Get in touch with us:",
    NITRIX: "Nitrix",
    privacy: "privacy",






    // --- Company Page ---

    companySubtitle: "about nitrix",
    companyTitle: "We build applications people love to use",
    companyInformationText_1: "Nitrix is about professionalism, creativity, and timeliness. Our team of experienced developers, designers, and QA engineers is a team of people who love and know how to do their job.",
    companyInformationText_2: "We are constantly improving our skills so that you are satisfied with our work in every stage of development. Nitrix actively integrates the latest technologies to ensure that you are always one step ahead of your competitors.",
    companyInformationText_3: "We can provide you with a dedicated team of outstanding specialists who will implement everything you need to successfully digitize your business and go worldwide.",

    companyOurValues: "Our Values",
    companyValue_1: "HIGH-QUALITY RESULT",
    companyValue_2: "MEETING DEADLINES",
    companyValue_3: "KEEPING IN TOUCH",

    companyValue_1_description: "Our team members do their best to make your wishes come true. We are always ready to share our fresh ideas to complete your project. We build your success with joint steps.",
    companyValue_2_description: "We work hard to ensure that you receive your project on time. That's why we at Nitrix always meet deadlines. We are proud to create your project in the shortest terms which will not affect or worsen the performance of our developers.",
    companyValue_3_description: "Our main belief is that communication with the client is the most necessary stage in creating a successful product. That's why Nitrix is always in touch with you and ready to listen to your needs.",


    companyProcessTitle: "At Nitrix we prefer to work this way",

    number01S: "S/01",
    number02S: "S/02",
    number03S: "S/03",
    number04S: "S/04",
    number05S: "S/05",
    number06S: "S/06",
    number07S: "S/07",
    number08S: "S/08",
    number09S: "S/09",

    QUESTION: "Question",
    RESEARCH: "Research",
    DEVELOP: "Develop",
    TEST: "Test",
    RELEASE: "Release",
    SUPPORT: "Support",

    companyProcessQuestionDescription: "We listen to you and your wishes so you get the most powerful final result.",
    companyProcessResearchDescription: "Our goal is to analyse the materials and your market. It allows us to provide you with the best user experience.",
    companyProcessDesignDescription: "We are always ready to supplement your project with a charming design that will attract new customers.",
    companyProcessDevelopDescription: "Quality development is what we guarantee to every client. We are proud of our team of professional developers.",
    companyProcessTestDescription: "Our QA specialists will check the integrity of all features on different browsers and devices to avoid any possible issues.",
    companyProcessReleaseDescription: "We monitor your project at all stages of release. It increases your chances of getting the desired solution.",
    companyProcessSupportDescription: "We provide you with full IT support to save you time and money.",



    // --- Careers Page---

    careersSubtitle: "join nitrix",
    careersTitle: "We love our work Just not on Saturdays",

    careersInformationTitle: "More than just a job",
    careersInformationText_1: "If you want to work in a nice friendly atmosphere and get a high salary, then Nitrix is the place for you. Our company values perseverance and honesty, so if you are endowed with these qualities, then we invite you to become a part of our team.",
    careersInformationText_2: "Nitrix is the team that specializes in native and Flutter app development for iOS & Android. You will get work experience that opens up new opportunities for you. We support your desire to become better and offer new directions of development.",

    careersAdvantagesTitle: "We have more than just cookies",

    careersAdvantagesCard_1_name: "time off",
    careersAdvantagesCard_2_name: "flexible benefits",
    careersAdvantagesCard_3_name: "SUCCESSFUL FUTURE",
    careersAdvantagesCard_4_name: "office or home",
    careersAdvantagesCard_5_name: "travel",
    careersAdvantagesCard_6_name: "education",

    careersAdvantagesCard_1_text: "You’ll get 20 days of paid annual leave, plus Ukraine holidays and 5 days of sick leave. ",
    careersAdvantagesCard_2_text: "Comfortable office in the city center with perfect work equipment.",
    careersAdvantagesCard_3_text: "Opportunities for career growth and competitive compensation.",
    careersAdvantagesCard_4_text: "We give you an opportunity to work remotely or in our comfortable office.",
    careersAdvantagesCard_5_text: "We provide a discount in a travel company for you and your family.",
    careersAdvantagesCard_6_text: "We provide you with free English courses and 50% discount on your professional classes.",




    // --- Services Page ---

    servicesProvideTitle: "Services we provide",

    servicesProvideTextOutsource: " IT outsourcing is about the rational use of resources. Such a service will save you from time-consuming tasks that require special attention. Delegate your work to Nitrix and convince yourself of our expertise.",
    servicesProvideTextOutstaff: "  Provide yourself with additional employees with the necessary skills. Nitrix is a company that offers outstaffing services. We understand your problems and are ready to provide the best specialists for your project.",
    servicesProvideTextDDT: "Hire a whole team of professionals who can develop your project from the beginning to the end. It allows you to take IT to a new level and impress your target audience. ",

    servicesStackTitle: "Our Technology Stack",
    servicesStackText: "The Nitrix team uses only modern and scalable technologies to implement your mobile and web applications. That is why you will always be one step ahead of your competitors.",

    servicesStackSubtitle_1: "Mobile Technologies",
    servicesStackSubtitle_2: "Web Technologies",
    servicesStackSubtitle_3: "Design",
    servicesStackSubtitle_4: "Non-developmental specialists",

    servicesStackTechnologyTitle_1: "iOS",
    servicesStackTechnologyTitle_2: "Android",
    servicesStackTechnologyTitle_3: "Cross Platform",
    servicesStackTechnologyTitle_4: "Frontend",
    servicesStackTechnologyTitle_5: "Backend",
    servicesStackTechnologyTitle_6: "Databases",
    servicesStackTechnologyTitle_7: "More tech",

    FAQ: "FAQ",

    servicesFaqQuestion_1: "Can I order a project that includes all the services you offer?",
    servicesFaqQuestion_2: "What is the difference between outstaffing and outsourcing?",
    servicesFaqQuestion_3: "How can I get a job at Nitrix?",

    servicesFaqText_1: "Yes, we will be happy to help you make your project a success. We are ready to walk the path with you from the development of an idea to its full implementation.",
    servicesFaqText_2: "Each of these services covers the need for staff, but in different ways. Outsourcing is when an organization delegates a task to another company. It is very convenient because it saves your time. In simple words, outstaffing is renting employees from another company. This option is perfect for companies that need a specific employee for a short period. You are lucky because Nitrix provides these services. We will carefully advise you on the option that suits your business.",
    servicesFaqText_3: "We are always interested in professional candidates. That is why in the careers section you can find a job that suits you. Fill out the necessary form and start growing with Nitrix.",


    // ---DDT Page ---


    DDTInformationTitle: "Set up own DDT",
    DDTInformationText_1: "Nitrix helps you become the best at what you do. We are ready to cover the personnel needs for your project. You can create your team and manage their actions yourself. In turn, we offer you highly qualified specialists who adore their work.",
    DDTInformationText_2: "Our dedicated development team model exists to provide you with services to speed up your software development, expand your internal team, or build specific tech expertise.",

    DDTValueTitle: "How we differ",

    DDTValueText_1: "Ukraine is a large country with inexhaustible IT resources. It allows us to provide our customers with the best service.",
    DDTValueText_2: "Finding a decent software developer takes a lot of time and effort. We are ready to provide the best candidates so you can start working immediately.",
    DDTValueText_3: "Regulate the number of people in the team according to the amount of work.",
    DDTValueText_4: "Nitrix believes that the best investment is in attracting the best talent in the market. Therefore, most of our employees are strong middle and senior engineers with a small percentage of juniors.",
    DDTValueText_5: "The evaluation of our customers is something we are proud of. Clients love our ability to build great personal relationships with them and provide them with great-quality code.",


    DDTProcessTitle: "How a Dedicated Development Team is created",

    DDTProcessesTitle_1: "Desires that form a team",
    DDTProcessesTitle_2: "Team creation",
    DDTProcessesTitle_3: "Talent selection",
    DDTProcessesTitle_4: "Team integration",
    DDTProcessesTitle_5: "The result",

    DDTProcessesText_1: "It all starts with your wish. At Nitrix, we are ready to listen to your suggestions and turn them into reality. We need to know your project and job description, team size and the workflow you need. This will help us gather specialists for your project.",
    DDTProcessesText_2: "In most cases, you can start work right away, because our employees are always open to new projects. But in very rare cases, we may need time to build the perfect team for you. Everything depends on your request.",
    DDTProcessesText_3: "We evaluate all candidates to compare their knowledge and technical skills. According to your request, we select the best candidate for the final interview with you.",
    DDTProcessesText_4: "We carefully integrate our team into your project. This minimizes any disruption that might occur. Choose the desired management approach and start working.",
    DDTProcessesText_5: "By hiring a dedicated team of software developers, you can completely fulfill the need for personnel.  Our professional staff will lead you to the best final result.",


    DDTProsTitle: "Pros of the Dedicated Development Team Model",

    DDTProsCardTitle_1: "A cost-efficient alternative",
    DDTProsCardTitle_2: "Transparent management",
    DDTProsCardTitle_3: "A committed team that adopts your culture",
    DDTProsCardTitle_4: "No-fuss hiring",

    DDTProsCardText_1: "A dedicated team will significantly save your costs. You don't need to pay taxes and raise the employee's salary. We work under the terms of the contract, which greatly facilitates the process of cooperation.",
    DDTProsCardText_2: "You can communicate with the team and analyse their work. Guide their actions and change direction depending on the circumstances.",
    DDTProsCardText_3: "We value your project and expect it from you. Mutual respect is a necessary attribute that accelerates your success.",
    DDTProsCardText_4: "You don't need to worry and stress about not having the right candidate. Trust Nitrix and we will assemble the best team that meets your needs. You only need to describe the end goal and we will provide the perfect employees.",



    DDTFaqQuestion_1: "Why do I need a dedicated development team model for my business?",
    DDTFaqQuestion_2: "Is it possible to control the dedicated development teamwork?",
    DDTFaqQuestion_3: "Can I pick a team if I'm on a tight budget?",

    DDTFaqText_1: "Such a model provides your project with many advantages. First of all, you save time and money, which is a success factor for any business. You also get the best specialists who easily cope with any amount of work.",
    DDTFaqText_2: "Yes! This is one of the advantages of this method. Manage a team from anywhere in the world. It is very convenient for a startup and a large business.",
    DDTFaqText_3: "Of course. Nitrix is a team of professionals who are ready to move toward. Therefore, leave your project request and we will choose the best option for you, taking into account your budget.",



    anotherServicesTitle: "Another services we propose",

    anotherServicesOutsourceTitle: "Outsource IT Services with Nitrix team",
    anotherServicesOutsourceText: "IT Outsourcing Services are an efficient way to deliver IT solutions to any business on a global scale. Common IT outsource services can include anything from web design to entire IT infrastructure system layouts.",
    anotherServicesOutsourceHoverTitle: "IT Outsourcing Services",

    anotherServicesOutstaffTitle: "Powered staff by highly skilled people",
    anotherServicesOutstaffText: "You will be impressed by our professionals. Each team member has enough skills to take your project to the next level. You can rely on us and watch your business become successful.",
    anotherServicesOutstaffHoverTitle: "IT Outstaffing Services",

    anotherServicesDDTTitle: "Hire DDT for Any Business Goals",
    anotherServicesDDTText: "Refuse the long selection of candidates for various positions. We offer a professional team of software developers, which are personally selected for your project.",
    anotherServicesDDTHoverTitle: "Dedicated Development Team",


    // ---Outstaff Page ---

    outstaffTitle: "Powered staff by highly skilled people",
    outstaffSubtitle: "IT Outstaffing Services",

    outstaffProcessTitle: "Benefits of the IT Outstaffing",

    outstaffProcessStepTitle_1: "High-quality talent mapping",
    outstaffProcessStepTitle_2: "Unlimited control",
    outstaffProcessStepTitle_3: "Cost-saving",
    outstaffProcessStepTitle_4: "Faster time to market",

    outstaffProcessStepText_1: "You can easily choose an IT specialist according to your needs: from regular software engineers to solution architects and tech leads.",
    outstaffProcessStepText_2: "​​Influence the actions of your team at any stage. Your project may change depending on your decisions.",
    outstaffProcessStepText_3: "You don't need to pay workers a salary if there are no projects or work. With the outstaff service, you pay clearly for the work done. Also, by hiring an outstaff employee, you spend the same money, but get the experience of the whole company.",
    outstaffProcessStepText_4: "Outstaffing is your chance to deliver your products to the market faster. You don't waste time looking for experienced and highly qualified team members. Just rely on Nitrix and feel the benefits of working with us.",


    outstaffSuitedTitle: "Outstaffing model is best suited for",

    outstaffSuitedBlockTitle_1: "ONGOING PROJECT",
    outstaffSuitedBlockTitle_2: "MAINTENANCE",
    outstaffSuitedBlockTitle_3: "TEMPORARY EMPLOYEE REPLACEMENT",

    outstaffSuitedBlockText_1: "We quickly select candidates according to specific needs to strengthen the project.",
    outstaffSuitedBlockText_2: "We help to fix bugs, maintain software functionality, and add new features to implement your best IT projects.",
    outstaffSuitedBlockText_3: "It is not a problem for your company when an employee is sick or goes on vacation. Nitrix team is ready to help you at any time.",


    outstaffComparisonTitle: "What is the difference between outstaffing and outsourcing?",
    outstaffComparisonText_1: "Outsourcing is when the customer gives freelancers the execution of a certain process and expects the desired result from them. The work of the staff is transparent to the client. In most cases, the consumer is only interested in what result the team will eventually provide.",
    outstaffComparisonText_2: "Outstaffing implies that the client is provided with certain employees for a specified time. The customer himself determines how to organize the work of the staff provided to him.",

    outstaffDifferenceTitle_1: "Outstaffing",
    outstaffDifferenceTitle_2: "Outsourcing",

    outstaffDifferenceText_1: "Flexibility in personnel management;",
    outstaffDifferenceText_2: "Guarantees and stability;",
    outstaffDifferenceText_3: "Full focus on the main project.",

    outstaffDifferenceText_4: "Improving the quality of non-core services;",
    outstaffDifferenceText_5: "Reducing labour costs;",
    outstaffDifferenceText_6: "Getting rid of social obligations;",
    outstaffDifferenceText_7: "Independence from circumstances.",



    // ---Outsource Page ---

    outsourceWorkTitle: "How does outsource work?",
    outsourceWorkText_1: "Outsourcing is a strategic decision that can improve your business as a whole. Its essence is to ensure you the best working conditions. You can delegate tasks that take a long time or are unfamiliar to you.",
    outsourceWorkText_2: "Nitrix will gladly open this service for you. This allows you to reduce costs, minimize the number of full-time employees, improve the quality of service and solve many other tasks.",

    outsourceProsCardTitle_1: "Cost Benefits",
    outsourceProsCardTitle_2: "Saving time",
    outsourceProsCardTitle_3: "Speedy and Scalable Resources",
    outsourceProsCardTitle_4: "Access to More Talent",

    outsourceProsCardText_1: "We always discuss the cost of the project with you before starting. This allows you to avoid any misunderstandings.",
    outsourceProsCardText_2: "By delegating several tasks to our employees, you will be able to work on other vital issues of the company.",
    outsourceProsCardText_3: "Allow yourself to improve your project with outsourcing. This is a quick and convenient way to increase or decrease resources according to needs.",
    outsourceProsCardText_4: "Each member of our team strives to make your project even more successful. To do this, we constantly improve our skills. This is a good opportunity to integrate the best employees into your business without additional effort.",

    outsourceSuitedTitle: "Outsourcing model is best suited for",

    outsourceSuitedBlockTitle_1: "STURTUPS THAT NEED TO SCALE FAST",
    outsourceSuitedBlockTitle_2: "IF THE TASK IS SIMPLE",
    outsourceSuitedBlockTitle_3: "TEMPORARY PROJECTS",

    outsourceSuitedBlockText_1: "If you are limited in time, then outsourcing will come in handy. This is a quick way to get service in the fastest time.",
    outsourceSuitedBlockText_2: "Forget about tasks that exhaust you. Nitrix will always help with solving tasks of any complexity.",
    outsourceSuitedBlockText_3: "Outsourcing is a treasure for temporary IT projects. This is an opportunity to get the necessary service in a short time.",


    outsourceProcessTitle: "How To Outsource IT Services",

    outsourceProcessTitle_1: "Planning Strategy",
    outsourceProcessTitle_2: "Analysis",
    outsourceProcessTitle_3: "Assembling the team",
    outsourceProcessTitle_4: "The result",

    outsourceProcessText_1: "Everything needs to be planned in detail for the project to be successful. This is exactly what will allow you to form a goal and strategy and understand what kind of service you need.",
    outsourceProcessText_2: "At this stage, it is necessary to study the request and analyze future steps. This allows us to determine the required level of service and costs.",
    outsourceProcessText_3: "We are interested in building the best team for your project. We guarantee you quality work from our professional developers, designers, and QA engineers.",
    outsourceProcessText_4: "We report to you about our progress every day. We are always in touch and ready to improve your business.",


    outsourceServicesTitle: "Outsource  Services We Provide",

    outsourceServicesCardTitle_1: "WEB APPLICATION SERVICES",
    outsourceServicesCardTitle_2: "MOBILE APPLICATION SERVICES",
    outsourceServicesCardTitle_3: "END-TO-END SOFTWARE DEVELOPMENT SERVICES",
    outsourceServicesCardTitle_4: "DEVELOPMENT TEAMS",
    outsourceServicesCardTitle_5: "QUALITY ASSURANCE",

    outsourceServicesCardText_1: ['Outsourcing contributes to the development of your startup or any other business project.', 'The Nitrix team deals with software. We promote a wide range of services related to the design, development, and maintenance of websites or web applications.'],
    outsourceServicesCardText_2: ['A mobile application is a key to a successful business. With this tool, you can expand your audience. Professional developers from the Nitrix company can handle the current project or create a native or hybrid mobile application from scratch.'],
    outsourceServicesCardText_3: ['Let us do all the work for you. The full development process is what we do best. Rely on the Nitrix team and secure yourself a profitable project.'],
    outsourceServicesCardText_4: ['Scale the number of people in the team without much effort. Nitrix offers to expand your technical potential to meet the needs of a specific project. Our specialized team has been created to solve the issues that are beyond your power so far.'],
    outsourceServicesCardText_5: ["Show your users the perfect end product. It's easy because the Nitrix team guarantees you a high level of execution of your project. Our quality control engineers can take over the testing of your product or verify an existing solution."],


    outsourceFaqQuestion_1: "How can outsourcing make my business grow?",
    outsourceFaqQuestion_2: "Can you develop an end-to-end project for my business?",
    outsourceFaqQuestion_3: "Do I need outsourcing for my startup?",

    outsourceFaqText_1: "This service can save your business and scale it. You can delegate certain tasks or fully entrust the creation of a specific project. To do this, use the help of the Nitrix team.",
    outsourceFaqText_2: "Yes, of course. We are ready to develop your idea or to suggest several new ones. At Nitrix, we believe that each client needs an individual approach. Fill out the application below and feel the benefits of working with Nitrix for yourself.",
    outsourceFaqText_3: "Yes. This service will speed up the work of the project as a whole. You'll be able to work on other important tasks while the Nitrix team builds your project from scratch.",




    // ---Startup Page ---

    startupSubtitle: "Movie app",
    startupTitle: "It’s time to Start Up the Show!",
    startupLink: "www.startup-show.tv",

    startupInformationSubtitle: "overview",
    startupInformationTitle: "Add all of your favorite m3u playlists",
    startupInformationText_1: "With Startup Show, you can add all of your favorite m3u playlists using our sleek-designed powerful built-in player. Being a Netflix competitor, this app empowers to watch movies, TV Shows and Live TV Streaming using VLC Player (iOS/Android) and ExoPlayer with FFmpeg (Android) depending on video format. The app also supports Chromecast and subtitles. With the ability to download movies, users can watch them offline anywhere.",
    startupInformationText_2: "Supporting many popular platforms Startup Show allows you to Airplay mirror/ cast to your big screen or take it with you on the go",


    // ---AquaPal Page ---

    aquapalSubtitle: "Water Tracker App",
    aquapalTitle: "Track your water balance with Aqua Pal",

    aquapalInformationSubtitle: "overview",
    aquapalInformationTitle: "Drink, Grow, Stay Healthy",
    aquapalInformationText_1: "Aqua Pal is a Water Tracker, that allows you to track how much water you drink with a click of a button. This is just what you need to make sure you stay healthy, hydrated and energetic! Tracking water in its basic stages: Drink water at a set time, record it in the app and follow the evolution of your plant.",
    aquapalInformationText_2: "The attractive design is what makes you open the app again and again and constantly refresh your water balance. For effective water intake, choose a convenient device and track your water rate.",

    aquapalInformationSubtitle_2: "Track Progress and Achieve Goals",
    aquapalInformationText_3: " Analyze your hydration progress. Aqua Pal is an application that is aimed at constantly renewing the water balance. Set a goal and track your daily water intake. In order to conduct a complete analysis, review previous data.",
    aquapalInformationText_4: "Daily water intake is calculated according to user’s weight and gender, based on research by the US National Academy of Engineering and Medicine: Dietary Reference Intakes for Water, Potassium, Sodium, Chloride, and Sulfate",

    aquapalInformationSubtitle_3: "Track and synchronize your hydration on different devices",
    aquapalInformationText_5: "Log your drinks, no matter where you are or what you're doing. Choose any platform and convenient device to make your water intake as efficient as possible",


    // ---Zise Page ---

    ziseSubtitle: "wallet app",
    ziseTitle: "A digital economy with capacity for all",

    ziseInformationSubtitle: "overview",
    ziseInformationTitle: "Protect your money and privacy",
    ziseInformationText_1: "Zise is a next-generation digital wallet designed specifically for storing and managing Nexa coins, a fast and secure cryptocurrency built on the Nexa blockchain.",
    ziseInformationText_2: "With a simple and intuitive interface, Zise makes it easy for users to securely store, send and receive Nexa coins, as well as monitor their transaction history and portfolio performance.",

    ziseInformationSubtitle_2: "Receive Coins",

    ziseInformationSubtitle_3: "Transactions",
    ziseInformationTitle_2: "Fast, secure and clear transactions",
    ziseInformationText_3: "Zise allows users to easily send and receive Nexa coins with just a few steps by scanning QR codes. The transaction feature is secure and fast, ensuring that funds are transferred quickly and safely.",
    ziseInformationText_4: "Users can also monitor their transaction history in real-time, providing complete transparency and control over their assets.",

    ziseInformationSubtitle_4: "Send Coins",

    ziseInformationSubtitle_5: "Settings",
    ziseInformationTitle_3: "Set up the seed phrase to access your crypto, no matter what happens to the wallet itself",



    // ---Joyn Page ---

    joynSubtitle: "master operator app",
    joynTitle: "Charge your EV and pay easily",

    joynInformationSubtitle: "overview",
    joynInformationTitle: "Add, Charge, Earn",
    joynInformationText_1: "Joyn is an app for owners of electric vehicle charging stations and owners of electric vehicles. With this app, station owners can earn money by pre-adding their station for reservation by other users.",
    joynInformationText_2: "In turn, a user who has an electric vehicle can select a suitable station on the map according to their needs (price, connector, parking, amenities, location, etc.) and book the time and date that suits them.",

    joynInformationSubtitle_2: "Follow your history and track your earnings",
    joynInformationText_3: "Keep track of your stats on your poynts over a certain period of time (most popular charging time for each day, the amount of money you have earned, number of charges, etc.) In addition, you can view a list of charging requests and confirm them.",

    joynInformationSubtitle_3: "Joyn Coyn",
    joynInformationText_4: "The app also plans a system of rewards that can be earned in the form of JoynCoyns currency, which the user can earn for certain actions (inviting friends, adding a station, booking, etc.) and then pay for the recharge free of charge.",


    // ---Joinuz Page ---

    joinuzSubtitle: "progressive web app",
    joinuzTitle: "The best job for a better life",
    joinuzLink:"www.joinuz.nl",

    joinuzInformationSubtitle: "overview",
    joinuzInformationTitle: "Work, Gain experience and Enjoy",
    joinuzInformationText_1: "Joinuz progressive web app was designed exclusively for its employees. This corporate application offers various features, such as receiving notifications related to their work, tracking working hours, accessing a blog, and obtaining information about training. Joinuz's PWA also has its own community, that helps users stay informed and improve their skills.",
    joinuzInformationText_2: "The app provides a seamless user experience and can be accessed from any device with a web browser, eliminating the need for employees to download a separate application.",

    joinuzInformationTitle_2:"Stay informed with notifications",
    joinuzInformationText_3:"PWA offers an efficient way for its employees to stay informed about their work-related activities with the ability to receive notifications on salary enrollment, time tracking reminders, and up-to-date information about events in the company, so employees are always aware of important information.",

    joinuzInformationTitle_3:"Stay up-to-date with blog",
    joinuzInformationText_4:"Joinuz blog provides users with access to a wide range of informative and engaging articles on various topics. From current events in the company to lifestyle and entertainment, the blog offers a diverse selection of content to suit different interests.",

    joinuzInformationTitle_4:"Continue learning with Joinuz Academy",
    joinuzInformationText_5:"Joinuz Academy allows users to sign up and access an extensive library of educational resources at any time. With a diverse range of courses and training materials available, users can enhance their skills and knowledge in various fields.",

    joinuzInformationTitle_5:"Ask the community, share ideas, and help others",
    joinuzInformationText_6:"Joinuz has its own big community, that the user will have access to via PWA. This is a platform that connects people with another like-minded individuals for sharing knowledge and ideas. Users can engage in discussions and ask for advices others in the community.",


    // ---Saqure Page ---

    saqureSubtitle: "massage app",
    saqureTitle: "Massage at home for your comfort",
    saqureLink: "www.saqure.com",

    saqureInformationSubtitle: "overview",
    saqureInformationTitle: "Stay calm and focused under stress",
    saqureInformationText_1: "SaQure is an on-demand massage service platform, where one type of users are specialists that can provide their services, and others are regular users, customers, who can schedule an appointment. In this project, we made an iOS app for customers. With the user-friendly interface of the app, they can book a massage therapist in one click.",



    // ---Ziksen Page ---

    ziksenSubtitle: "music app",
    ziksenTitle: "Listen to the best music at any time",
    ziksenLink: "www.ziksen.com",

    ziksenInformationSubtitle: "overview",
    ziksenInformationTitle: "Create your playlists and listen to it offline",
    ziksenInformationText_1: "This is an Android app for audio music listening and clips watching. This one was made from scratch with the help of 2 Android developers and it took only 3.5 months to release. The application initially made for users from developing countries and should have spread through mobile phone operators. It also has all the features that you can find in modern apps like Apple Music or Google Play Music. The app is using ExoPlayer for playback, Firebase Analytics and Adjust for statistics tracking and Firebase Cloud Messaging for push notifications.",

    // ---Rainbow Page ---

    rainbowSubtitle: "social app",
    rainbowTitle: "Share your best memories with world",

    rainbowInformationSubtitle: "overview",
    rainbowInformationTitle: "Keep your best moments here",
    rainbowInformationText_1: "Social photo service application made as an Instagram prototype. The app has its such own features like sharing photos and photo collections with elements of a social network. The app is currently not available in the Stores.",

    // ---Halves Page ---

    halvesSubtitle: "Dating app",
    halvesTitle: "Find your soul mate with Halves",

    halvesInformationSubtitle: "overview",
    halvesInformationTitle: "Just fall in love with Halves",
    halvesInformationText_1: "Halves is a dating app that helps single people to find their soulmates. The application made only for iOS developed from design to the Store and contains basic features: liking interesting profiles, matching, free chatting. Currently not available in the Store.",

    // ---Tailors Page ---

    tailorsSubtitle: "e-commerce  app",
    tailorsTitle: "Let us share our warmth",

    tailorsInformationSubtitle: "overview",
    tailorsInformationTitle: "Helping you create cozy things",
    tailorsInformationText_1: "This application is an e-commerce fabric store made by using the Flutter framework. Tailors app gives the opportunity to select a fabric, add to cart, pay for an order, pick a type of transportation in two ways - delivery to customer's address or takeaway. The app is definitely easy-to-use and stands out among other online stores.",


    // ---Israel Page ---

    israelSubtitle: "Movie app",
    israelTitle: "Let's explore the best TV shows of Israel",

    israelInformationSubtitle: "overview",
    israelInformationTitle: "Discover the world of Israel TV",
    israelInformationText_1: "Israel TV gives you access to the best movies, TV Shows, Live TV Streaming and radio stations from Israel. With this app you will enjoy  watching Israel TV channels or listening to online Israel radio broadcasts and music on your devices, no matter where you are.",
    israelInformationText_2: "Thousands of Israel films, serials, nostalgia, telenovelas, content for children, entertainment shows and loads of content await you in the VOD library.",


    israelInformationSubtitle_2: "Watch Israel TV on All Your Devices",
    israelInformationText_3: "We make it comfortable. Watch favorites movies, show on your Phone, Tablets, TV or use your Computer!",

}






