import React from 'react';
import './CompanyTeam.css';
import Flashlight from '../Flashlight/Flashlight';

import CEO1 from '../../img/company/ceo1.webp';
import CEO2 from '../../img/company/ceo2.webp';
import COO1 from '../../img/company/coo1.webp';
import COO2 from '../../img/company/coo2.webp';
import HR1 from '../../img/company/hr1.webp';
import HR2 from '../../img/company/hr4.webp';
import PM1 from '../../img/company/pm1.webp';
import PM2 from '../../img/company/pm2.webp';
import cursor from '../../img/company/LinkedIn_cursor.webp';

import CollageTeam1920 from './CollageTeam1920/CollageTeam1920';
import collageTeamTablet from '../../img/company/CallageTeamTablet.webp';
import collageTeamMobile from '../../img/company/CallageTeamMobile.webp';



const CompanyTeam = () => {

    return (
        <>

            <div className="company-team team" >

                <h2 className="team-title">Meet the leadership driving Nitrix’s progress</h2>

                <div className="team-wrapper" id="horizontal-scroller">

                    <div className="team-card">
                        <div className="team-flashlight-wrapper">
                            <Flashlight
                                imgFront={CEO1}
                                imgBack={CEO2}
                                imgMobile={CEO1}
                                imgFrontId='img__ceo1'
                                imgBackId='img__ceo2'
                                loopSize={150}
                                cursor={cursor}
                                cursorHeight={150}
                                cursorWidth={150}
                                cursorId='cursor__ceo'
                                href='https://www.linkedin.com/in/nikita-axyonov/'
                                newTab='_blank'
                            />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <p className="team-name">Nikita Axyonov</p>
                            <p className="team-position">CEO</p>
                            <a className="team-link" href="https://www.linkedin.com/in/nikita-axyonov/" target="_blank">LN</a>
                        </div>
                    </div>

                    <div className="team-card">
                        <div className="team-flashlight-wrapper">
                            <Flashlight
                                imgFront={COO1}
                                imgBack={COO2}
                                imgMobile={COO1}
                                imgFrontId='img__coo1'
                                imgBackId='img__coo2'
                                loopSize={150}
                                cursor={cursor}
                                cursorHeight={150}
                                cursorWidth={150}
                                cursorId='cursor__coo'
                                href='https://www.linkedin.com/in/yana-levandovska-/'
                                newTab='_blank'
                            />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <p className="team-name">Yana Levandovska</p>
                            <p className="team-position">COO</p>
                            <a className="team-link" href="https://www.linkedin.com/in/yana-levandovska-/" target="_blank">LN</a>
                        </div>
                    </div>



                    <div className="team-card">
                        <div className="team-flashlight-wrapper">
                            <Flashlight
                                imgFront={PM1}
                                imgBack={PM2}
                                imgMobile={PM1}
                                imgFrontId='img__pm1'
                                imgBackId='img__pm2'
                                loopSize={150}
                                cursor={cursor}
                                cursorHeight={150}
                                cursorWidth={150}
                                cursorId='cursor__pm'
                                href='https://www.linkedin.com/in/maksym-lysiahin/'
                                newTab='_blank'
                            />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <p className="team-name">Maksym Lysiahin</p>
                            <p className="team-position">PM</p>
                            <a className="team-link" href="https://www.linkedin.com/in/maksym-lysiahin/" target="_blank">LN</a>
                        </div>
                    </div>


                    <div className="team-card">
                        <div className="team-flashlight-wrapper">
                            <Flashlight
                                imgFront={HR1}
                                imgBack={HR2}
                                imgMobile={HR1}
                                imgFrontId='img__hr1'
                                imgBackId='img__hr2'
                                loopSize={150}
                                cursor={cursor}
                                cursorHeight={150}
                                cursorWidth={150}
                                cursorId='cursor__hr'
                                href='https://www.linkedin.com/in/vasilyeva-polina/'
                                newTab='_blank'
                            />
                        </div>
                        <div style={{ position: 'relative' }}>
                            <p className="team-name">Polina Vasilyeva</p>
                            <p className="team-position">HR</p>
                            <a className="team-link team-link-hr" href="https://www.linkedin.com/in/vasilyeva-polina/" target="_blank">LN</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="collageTeam1920">
                <CollageTeam1920 />
            </div>

            <img className="collageTeamTablet" src={collageTeamTablet} alt="nitrix" />
            <img className="collageTeamMobile" src={collageTeamMobile} alt="nitrix" height={450} width={767} />

        </>
    )
}

export default CompanyTeam;