import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import articlesService from "./articlesService";

const initialState = {
    articles: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
};

export const getAllArticles = createAsyncThunk('articles/getAll',

    async (articles, thunkAPI) => {
        try {
            return await articlesService.getAllArticles()
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getOneArticle = createAsyncThunk('articles/getOne',

    async (_id, thunkAPI) => {
        try {
            return await articlesService.getOneArticle(_id)
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const createArticle = createAsyncThunk('articles/create',
  
    async (article, thunkAPI) => {
        try {
            return await articlesService.createArticle(article)
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const updateArticle = createAsyncThunk('articles/update',

    async (data, thunkAPI) => {
        try {
            await articlesService.updateArticle(data)
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const changeOfArticleVisibility = createAsyncThunk('articles/changeOfArticleVisibility',

    async (data, thunkAPI) => {
        try {
            await articlesService.changeOfArticleVisibility(data)
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const removeArticle = createAsyncThunk('articles/remove',

    async (_id, thunkAPI) => {
        try {
            return await articlesService.removeArticle(_id)
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const getCategory = createAsyncThunk('articles/getCategory',

    async (category, thunkAPI) => {
        try {
            return await articlesService.getCategory(category)
        } catch (error) {
            const message = error.response.data
            return thunkAPI.rejectWithValue(message)
        }
    }
)



export const articlesSlice = createSlice({
    name: 'articles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllArticles.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllArticles.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.articles = action.payload
            })
            .addCase(getAllArticles.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = 'Error getAll articles'
                state.articles = null
            })

            .addCase(createArticle.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createArticle.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.articles.unshift(action.payload)
                console.log(action.payload, 'action payload')
            })
            .addCase(createArticle.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = 'Error create article'
                state.articles = null
            })

            .addCase(updateArticle.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateArticle.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(updateArticle.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = 'Error update article'
                state.articles = null
            })

            .addCase(changeOfArticleVisibility.pending, (state) => {
                state.isLoading = true
            })
            .addCase(changeOfArticleVisibility.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(changeOfArticleVisibility.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = 'Error changeOfArticleVisibility'
                state.articles = null
            })

            .addCase(removeArticle.pending, (state) => {
                state.isLoading = true
            })
            .addCase(removeArticle.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.articles = state.articles.filter(
                    (article) => article._id != action.payload
                )
            })
            .addCase(removeArticle.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = 'Error delete article'
                state.articles = null
            })

            .addCase(getCategory.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCategory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.articles = action.payload
            })
            .addCase(getCategory.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = 'Error getCategory'
                state.articles = null
            })
    }

})

export const { } = articlesSlice.actions;

export const articlesReducer = articlesSlice.reducer;