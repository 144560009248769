import axios from "axios";
import { API_URL } from "../../const";

const getAllArticles = async () => {

    const res = await axios.get(`${API_URL}/api/article/all`);
    return res.data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
}

const getOneArticle = async (articleURL) => {

    const res = await axios.get(`${API_URL}/api/article/one/` + articleURL);
    return res.data;
}

const getCategories = async (inActiveArticles) => {
    const res = await axios.get(`${API_URL}/api/article/categories?inActiveArticles=${inActiveArticles ? 'true' : 'false'}`);
    return res.data;
}

const createArticle = async (data) => {

    const token = localStorage.getItem('token')
    const res = await axios.post(`${API_URL}/api/article/create`, data,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
    return res.data;
}

const updateArticle = async (data) => {

    const token = localStorage.getItem('token')
    const res = await axios.put(`${API_URL}/api/article`, data,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
    return res.data;
}

const changeOfArticleVisibility = async (data) => {

    const token = localStorage.getItem('token')
    const res = await axios.put(`${API_URL}/api/article/change-visibility`, data,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
    return res.data;
}

const removeArticle = async (_id) => {

    const token = localStorage.getItem('token')
    await axios.delete(`${API_URL}/api/article/` + _id,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    );
    return _id;
}


const getCategory = async (category) => {

    const res = await axios.get(`${API_URL}/api/article/all/?category=${encodeURIComponent(category)}`);
    return res.data.data;
}

const articlesService = {
    createArticle,
    getAllArticles,
    getOneArticle,
    getCategories,
    updateArticle,
    changeOfArticleVisibility,
    removeArticle,
    getCategory
}

export default articlesService;